export default {
  transferProfile: 'Профиль передан',
  profileCreate: 'Создание профиля',
  delete: 'Удаление профиля',
  start: 'Запуск профиля',
  stop: 'Конец сессии',
  cookiesImport: 'Импорт Кук',
  cookiesExport: 'Экспорт Кук',
  profileUpdate: 'Изменение профиля',
  accessCreate: 'Изменение прав доступа',
  accessUpdate: 'Изменение прав доступа',
  accessRemove: 'Изменение прав доступа',
  proxyUpdate: 'Изменение прокси профиля',
  proxyRemove: 'Удаление прокси профиля',
  statusUpdate: 'Изменение статуса профиля',
  statusRemove: 'Удаление статуса профиля',
  tagsUpdate: 'Изменение тегов профиля',
  tagsRemove: 'Удаление тегов профиля',
  notesUpdate: 'Изменение заметки профиля',
  notesRemove: 'Удаление заметки профиля',
  profileMoveToBasket: 'Перемещение профиля в корзину',
  profileRestoreFromTrash: 'Восстановление профиля из корзины',
  profileMoveTrashToArchive: 'Перемещение профиля из корзины в архив',
  profileMoveArchiveToBackup: 'Перемещение профиля из архива в бекапы',
};
