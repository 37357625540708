<template>
  <div>
    <topbar />
    <main-table />
  </div>
</template>

<script>
import HistoryMainTable from '@/components/browserProfilesHistory/HistoryMainTable.vue';
import HistoryTopbar from '../components/browserProfilesHistory/HistoryTopbar.vue';

export default {
  name: 'BrowserProfilesHistory',
  components: { MainTable: HistoryMainTable, Topbar: HistoryTopbar },
};
</script>

<style scoped>

</style>
