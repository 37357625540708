<template>
  <div class="d-flex align-center justify-space-between">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchParams.browserProfileId"
            clearable
            label="Айди профиля"
            v-bind="attrs"
            v-on="on"
            @input="search();"
          />
        </template>
      </v-menu>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchParams.userName"
            clearable
            label="email юзера"
            v-bind="attrs"
            v-on="on"
            @input="search();"
          />
        </template>
      </v-menu>

      <v-checkbox
        v-model="searchParams.searchInTeam"
        label="Искать по всей тиме"
        hide-details
        class="mt-0 pt-0 mr-3 ml-3"
        @change="search"
      />
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchParams.machineId"
            clearable
            label="machineId"
            v-bind="attrs"
            v-on="on"
            @input="search();"
          />
        </template>
      </v-menu>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        close-delay="mr-4"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchParams.date"
            clearable
            label="по дате"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            class="mr-4"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="searchParams.date"
          class="mt-3 mb-6"
          range
          @input="search();"
        />
      </v-menu>
      <profile-history-filter
        v-model="searchParams.profileEvents"
        label="Тип"
        :items-list="profileEventsList"
        :selected-items="searchParams.profileEvents"
        item-text="name"
        selected-events
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import profileEvents from '@/constants/browserProfile/profileEvents';
import ProfileHistoryFilter from '@/components/browserProfilesHistory/ProfileHistoryFilterSelect.vue';
import definedTypes from '@/constants/browserProfile/definedTypes';
import { mapGetters } from 'vuex';
import customSort from '../../mixins/customSort';

export default {
  name: 'HistoryTopbar',
  components: { ProfileHistoryFilter },
  mixins: [customSort],
  data() {
    return {
      browserProfileId: null,
      menu: '',
      profileEvents,
      selectedEvents: [],
    };
  },
  computed: {
    ...mapFields('browserProfilesHistory', ['searchParams']),
    ...mapFields('browserProfilesHistory', ['history']),
    ...mapGetters({
      pagination: 'browserProfilesHistory/pagination',
    }),

    profileEventsList() {
      return this.profileEvents.map((event) => ({
        value: event,
        name: this.getItemTypeName(event),
      }));
    },

  },
  watch: {
    searchParams: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },
  mounted() {
    this.search();
  },
  created() {
    this.closeRange = this.$_.debounce(this.closeRange, 2000);
    this.search = this.$_.debounce(this.search, 500);
    this.searchParams.searchInTeam = true;
  },

  methods: {
    closeRange() {
      this.menu = false;
    },
    getItemTypeName(type) {
      return definedTypes[type];
    },
    search() {
      this.$store.dispatch('browserProfilesHistory/loadHistory', {
        page: this.pagination.currentPage,
        limit: this.pagination.perPage,
      });
      this.closeRange();
    },

  },
};
</script>
