var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Айди профиля"},on:{"input":function($event){return _vm.search();}},model:{value:(_vm.searchParams.browserProfileId),callback:function ($$v) {_vm.$set(_vm.searchParams, "browserProfileId", $$v)},expression:"searchParams.browserProfileId"}},'v-text-field',attrs,false),on))]}}])}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"email юзера"},on:{"input":function($event){return _vm.search();}},model:{value:(_vm.searchParams.userName),callback:function ($$v) {_vm.$set(_vm.searchParams, "userName", $$v)},expression:"searchParams.userName"}},'v-text-field',attrs,false),on))]}}])}),_c('v-checkbox',{staticClass:"mt-0 pt-0 mr-3 ml-3",attrs:{"label":"Искать по всей тиме","hide-details":""},on:{"change":_vm.search},model:{value:(_vm.searchParams.searchInTeam),callback:function ($$v) {_vm.$set(_vm.searchParams, "searchInTeam", $$v)},expression:"searchParams.searchInTeam"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"machineId"},on:{"input":function($event){return _vm.search();}},model:{value:(_vm.searchParams.machineId),callback:function ($$v) {_vm.$set(_vm.searchParams, "machineId", $$v)},expression:"searchParams.machineId"}},'v-text-field',attrs,false),on))]}}])}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","close-delay":"mr-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"clearable":"","label":"по дате","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.searchParams.date),callback:function ($$v) {_vm.$set(_vm.searchParams, "date", $$v)},expression:"searchParams.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"mt-3 mb-6",attrs:{"range":""},on:{"input":function($event){return _vm.search();}},model:{value:(_vm.searchParams.date),callback:function ($$v) {_vm.$set(_vm.searchParams, "date", $$v)},expression:"searchParams.date"}})],1),_c('profile-history-filter',{attrs:{"label":"Тип","items-list":_vm.profileEventsList,"selected-items":_vm.searchParams.profileEvents,"item-text":"name","selected-events":""},model:{value:(_vm.searchParams.profileEvents),callback:function ($$v) {_vm.$set(_vm.searchParams, "profileEvents", $$v)},expression:"searchParams.profileEvents"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }