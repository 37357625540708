var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"items":_vm.history.all,"item-key":"id","headers":_vm.cols,"fixed-header":"","height":_vm.innerHeight - 16 - 79 - 59,"items-per-page":_vm.footerOptions.itemsPerPage,"hide-default-footer":"","show-select":"","loading":_vm.loading.mainTable,"custom-sort":_vm.customSort,"sort-by":_vm.sortBy(),"sort-desc":_vm.sortDesc(),"sortable":false},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.username)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemTypeName(item.type))+" ")]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(item.data)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(_vm.moment(item.created_at ).format('DD.MM.YYYY HH:mm'))+" ")])]}},{key:"item.machineId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(item.machineId)+" ")])]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"options":_vm.footerOptions,"pagination":_vm.footerPagination,"items-per-page-options":[10, 30, 50]},on:{"update:options":_vm.updateFooterOptions}})]},proxy:true}],null,true),model:{value:(_vm.history.selected),callback:function ($$v) {_vm.$set(_vm.history, "selected", $$v)},expression:"history.selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }