<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="history.selected"
      :items="history.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 79 - 59"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      show-select
      :loading="loading.mainTable"
      :custom-sort="customSort"
      :sort-by="sortBy()"
      :sort-desc="sortDesc()"
      :sortable="false"
    >
      <template #[`item.user`]="{item}">
        {{ item.user.username }}
      </template>
      <template #[`item.type`]="{item}">
        {{ getItemTypeName(item.type) }}
      </template>
      <template #[`item.data`]="{item}">
        <div style=" word-break: break-all">
          {{ item.data }}
        </div>
      </template>
      <template #[`item.created_at`]="{item}">
        <div style="max-width: 150px">
          {{ moment(item.created_at ).format('DD.MM.YYYY HH:mm') }}
        </div>
      </template>
      <template #[`item.machineId`]="{item}">
        <div style=" word-break: break-all">
          {{ item.machineId }}
        </div>
      </template>
      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';

import definedTypes from '@/constants/browserProfile/definedTypes';
import customSort from '../../mixins/customSort';

export default {
  name: 'HistoryTimeMainTable',

  components: {
    // CellActions,
  },

  mixins: [customSort],

  data() {
    return {
      moment,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'browserProfilesHistory/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'browserProfilesHistory/pagination',
      profile: 'main/profile',
    }),
    ...mapFields('browserProfilesHistory', ['history']),
    cols() {
      const cols = [];
      cols.push({ text: 'Айди профиля', value: 'browserProfileId', sortable: false });
      cols.push({ text: 'Пользователь', value: 'user', sortable: false });
      cols.push({ text: 'Событие', value: 'type', sortable: false });
      cols.push({ text: 'Когда', value: 'created_at', sortable: false });
      cols.push({ text: 'Данные', value: 'data', sortable: false });
      cols.push({ text: 'machineID', value: 'machineId', sortable: false });
      return cols;
    },
    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },
  methods: {
    updateFooterOptions(options) {
      this.$store.dispatch('browserProfilesHistory/loadHistory', {
        page: options.page,
        limit: options.itemsPerPage,
      });
    },

    getItemTypeName(type) {
      // eslint-disable-next-line no-prototype-builtins
      if (definedTypes.hasOwnProperty(type)) {
        return definedTypes[type];
      }
      return type;
    },
  },
};
</script>
