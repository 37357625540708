export default [
  'profileCreate',
  'start',
  'stop',
  'cookiesImport',
  'cookiesExport',
  'profileUpdate',
  'transferProfile',
  'accessUpdate',
  'proxyUpdate',
  'proxyRemove',
  'statusUpdate',
  'statusRemove',
  'tagsUpdate',
  'tagsRemove',
  'notesUpdate',
  'notesRemove',
  'delete',
  'profileMoveToBasket',
  'profileRestoreFromTrash',
  'profileMoveTrashToArchive',
  'profileMoveArchiveToBackup',
];
